import "@af-utils/scrollend-polyfill";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { app } from "./config.ts";
import { enableMocking } from "./enable-mocking.ts";
import "./index.css";

if (import.meta.env.VITE_ENV === "production") {
  Sentry.init({
    dsn: "https://dca8fcc20c44fa284bc9abb12547853a@o4508086011887616.ingest.de.sentry.io/4508086019031120",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        mask: ["[type='password']"],
      }),
      Sentry.extraErrorDataIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [app.apiUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
