import { ElementType } from "react";

import { routes } from "@/routes/paths";
import { Cog, CreditCard, MessageCircleQuestion, Utensils } from "lucide-react";

interface MenuItem {
  header: string;
  section: {
    name: string;
    href: string;
    icon: ElementType;
    dropdownItems?: {
      name: string;
      href: string;
      badge?: string;
    }[];
  }[];
}

export const menu: MenuItem[] = [
  {
    header: "Dashboard",
    section: [
      {
        name: "Restaurants",
        href: routes.dashboard.root,
        icon: Utensils,
      },
    ],
  },
  {
    header: "Account",
    section: [
      {
        name: "Billing",
        href: routes.account.billing,
        icon: CreditCard,
      },
      {
        name: "Settings",
        href: routes.account.settings,
        icon: Cog,
      },
      {
        name: "Support",
        href: routes.account.support,
        icon: MessageCircleQuestion,
      },
    ],
  },
];
