import { Paper, ScrollArea } from "@mantine/core";
import { Outlet } from "react-router-dom";

import Logo from "@/components/Logo";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import classes from "./root.module.css";

export function DashboardLayout() {
  return (
    <div className={classes.root}>
      <Paper withBorder className={classes.sidebarWrapper} visibleFrom="xl">
        <div className={classes.logoWrapper}>
          <Logo size={28} />
        </div>
        <ScrollArea flex="1" px="md">
          <Sidebar />
        </ScrollArea>
      </Paper>
      <div className={classes.content}>
        <Header />
        <main className={classes.main}>
          <Outlet />
        </main>
      </div>
    </div>
  );
}
