import { fmap } from "@qrbite/shared/utils/general.utils";
import imageCompression, { Options } from "browser-image-compression";
import { nanoid } from "nanoid";

export async function handleCompressImages(
  blobUrls: string[],
  onProgress: (data: {
    type: "compressingImage";
    current: number;
    total: number;
  }) => void,
  options?: Options,
) {
  const compressedFiles = await Promise.all(
    blobUrls.map((blobUrl, i) =>
      compressImage(blobUrl, options).then((compressedFile) => {
        onProgress({
          type: "compressingImage",
          current: i + 1,
          total: blobUrls.length,
        });
        return compressedFile;
      }),
    ),
  );

  return compressedFiles;
}

async function compressImage(blobUrl: string, options?: Options) {
  const imageFile = await blobUrlToFile(blobUrl);

  const extension = getFileExtension(imageFile.type);

  if (extension === "svg") {
    console.log("SVG image detected, skipping compression");
    return imageFile;
  }

  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  try {
    const compressedFile = await imageCompression(
      imageFile,
      options ?? {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1200,
      },
    );

    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

    return compressedFile.size < imageFile.size ? compressedFile : imageFile;
  } catch (error) {
    console.log("Failed to compress image");
    throw error;
  }
}

function getFileExtension(blobType: string) {
  const mimeTypes: { [key: string]: string } = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/bmp": "bmp",
    "image/webp": "webp",
    "image/tiff": "tiff",
    "image/svg+xml": "svg",
    "image/x-icon": "ico",
    "image/heif": "heif",
    "image/heic": "heic",
    "image/avif": "avif",
    "image/x-xbitmap": "xbm",
    "image/x-pixmap": "xpm",
    "image/x-xwindowdump": "xwd",
    "image/vnd.adobe.photoshop": "psd",
    "image/vnd.microsoft.icon": "ico",
    "image/jp2": "jp2",
    "image/jpx": "jpx",
    "image/jpm": "jpm",
    "image/jxr": "jxr",
    "image/vnd.wap.wbmp": "wbmp",
    "image/emf": "emf",
    "image/wmf": "wmf",
    "image/apng": "apng",
    "application/pdf": "pdf",
  };

  return mimeTypes[blobType];
}

export async function blobUrlToFile(blobUrl: string) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  const extension = fmap(getFileExtension(blob.type), (ext) => `.${ext}`) ?? "";
  const fileName = `${nanoid()}${extension}`;

  return new File([blob], fileName, { type: blob.type });
}
