import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { Stack, Title, NavLink } from "@mantine/core";

import { menu } from "./menu-sections";
import classes from "./sidebar.module.css";

export function Sidebar() {
  const { pathname } = useLocation();

  return (
    <Stack gap="xl">
      {menu.map((item) => (
        <div key={item.header}>
          <Title
            className={classes.sectionTitle}
            fw="normal"
            fz="xs"
            mb="sm"
            order={6}
          >
            {item.header}
          </Title>

          {item.section.map((subItem) =>
            subItem.dropdownItems ? (
              <NavLink
                key={subItem.name}
                active={pathname.includes(subItem.href)}
                childrenOffset={0}
                className={classes.sectionLink}
                label={subItem.name}
                leftSection={<subItem.icon />}
                variant="subtle"
              >
                {subItem.dropdownItems.map((dropdownItem) => (
                  <NavLink
                    key={dropdownItem.name}
                    active={pathname.includes(dropdownItem.href)}
                    className={classes.sectionDropdownItemLink}
                    component={RouterLink}
                    label={dropdownItem.name}
                    leftSection={<span className="dot" />}
                    to={dropdownItem.href}
                    variant="subtle"
                  />
                ))}
              </NavLink>
            ) : (
              <NavLink
                key={subItem.name}
                className={classes.sectionLink}
                component={RouterLink}
                label={subItem.name}
                leftSection={<subItem.icon />}
                to={subItem.href}
                variant="subtle"
              />
            ),
          )}
        </div>
      ))}
    </Stack>
  );
}
