import { queryClient } from "@/core/query-client";
import AuthProvider from "@/providers/auth-provider";
import { Router } from "@/routes/router";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";

export default function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
